import React from 'react';
import ContactInfo from './ContactInfo';

class ContactPage extends React.Component {
  render() {
    return (
      <div>
        <ContactInfo />
      </div>
    );
  }
}

export default ContactPage;
